<!--
 * @Descripttion:
 * @version:
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2020-11-17 15:55:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-03-12 17:58:43
-->
<template>
</template>

<script>
  import { Toast } from "vant"
  import global from "@/common/global"
  export default{
    data(){
      return {
      }
    }
    ,mounted:function(){
		let that = this
      //获取openid
      let edCurrentUser = this.$ls.get("edCurrentUser");
      if(edCurrentUser){
        //获取access_token
        let tokenUrl = "/wxauth/cgi-bin/token?grant_type=client_credential&appid="+global.appid+"&secret="+global.secret;
        this.$ajax
          .get(tokenUrl)
          .then((res)=>{
            if(res.status=="200"){
              let token = res.data.access_token;
              let nicknameUrl = "/wxauth/cgi-bin/user/info?access_token="+token+"&openid="+edCurrentUser.openid+"&lang=zh_CN";
              //获取昵称
              this.$ajax
                .get(nicknameUrl)
                .then((res)=>{
                  if(res.status=="200"){
                    if(res.data.subscribe=="1"){
                      //写入数据库
                      let nickname = res.data.nickname;
					            // let base64Nickname = that._base64_encode(res.data.nickname)
                      let data = {};
                      data.id = edCurrentUser.id;
                      data.nickname = nickname
                      data.unionid = res.data.unionid;
                      data.description ='专家记';
                      this.$ajax({
                        method: "post",
                        url: "/gateway/integral/mobile/customer/updateNicknameNew",
                        headers:{
                          'X-Access-Token':this.$ls.get("token")
                        },
                        params: {
                          openid:edCurrentUser.openid,
                        },
                        data:data
                      }).then((res)=>{
                          if(res.status=="200"&&res.data.retCode=="0"){
                            //设置昵称
                            let ddd1 = this.$ls.get("edCurrentUser");
                            ddd1.userExternal.name = nickname;
                            this.$ls.set("edCurrentUser",ddd1);
                            if(this.$ls.get('isPc')){
                                window.location.href = global.pcUrl;
                            }else{
                                this.$router.push('/home')
                            }
                          }else{
                            this.$toast("出现异常,请稍候重试");
                          }
                        });
                    }else{
                      this.$toast.fail("请关注公众号");
                      setTimeout(() => {
                          window.location.href=global.OfficialAccountUrl
                      }, 1200);
                      return
                    }
                  }else{
                    this.$toast("出现异常,请稍候重试");
                  }
                });
            }else{
              this.$toast("出现异常,请稍候重试");
            }
          });
      }else{
        this.$toast("请先注册");
        this.$router.push({ name: "index" });
      }
    },
	methods:{
		
      _utf8_encode:function(text){
        text = text.replace(/\r\n/g, "\n");
        var utftext = "";
        for (var n = 0; n < text.length; n++) {
          var c = text.charCodeAt(n);
          if (c < 128) {
            utftext += String.fromCharCode(c);
          } else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
          } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
          }
        }
        return utftext;
      },
      _utf8_decode:function(text){
        var string = "";
        var i = 0;
        var c = c1 = c2 = 0;
        while (i < text.length) {
          c = text.charCodeAt(i);
          if (c < 128) {
            string += String.fromCharCode(c);
            i++;
          } else if ((c > 191) && (c < 224)) {
            c2 = text.charCodeAt(i + 1);
            string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
            i += 2;
          } else {
            c2 = text.charCodeAt(i + 1);
            c3 = text.charCodeAt(i + 2);
            string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
            i += 3;
          }
        }
        return string;
      },
      _base64_encode:function(text){
        var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;
        text = this._utf8_encode(text);
        while (i < text.length) {
          chr1 = text.charCodeAt(i++);
          chr2 = text.charCodeAt(i++);
          chr3 = text.charCodeAt(i++);
          enc1 = chr1 >> 2;
          enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
          enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
          enc4 = chr3 & 63;
          if (isNaN(chr2)) {
            enc3 = enc4 = 64;
          } else if (isNaN(chr3)) {
            enc4 = 64;
          }
          output = output +
            _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
            _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
        }
        return output;
      },
      _base64_decode:function(text){
        var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;
        text = text.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (i < text.length) {
          enc1 = _keyStr.indexOf(text.charAt(i++));
          enc2 = _keyStr.indexOf(text.charAt(i++));
          enc3 = _keyStr.indexOf(text.charAt(i++));
          enc4 = _keyStr.indexOf(text.charAt(i++));
          chr1 = (enc1 << 2) | (enc2 >> 4);
          chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
          chr3 = ((enc3 & 3) << 6) | enc4;
          output = output + String.fromCharCode(chr1);
          if (enc3 != 64) {
            output = output + String.fromCharCode(chr2);
          }
          if (enc4 != 64) {
            output = output + String.fromCharCode(chr3);
          }
        }
        output = this._utf8_decode(output);
        return output;
      },
	}
  }
</script>

<style>
</style>
